<template>

  <div class="form position-relative">

    <v-overlay
      absolute
      color="white"
      opacity="1"
      :value="sent"
    >
      <div class="pa-6 text-center">
        <div class=""><v-icon color="primary" size="50" >mdi-email</v-icon></div>
        <p class="grey--text">{{$t('FORM.PASSWORD_RECOVERY_SENT',{email})}}</p>
        <v-btn
          x-large
          outlined
          color="primary"
          @click="close"
        >
          <v-icon class="mr-2">mdi-arrow-left</v-icon><span>{{$t('BACK')}}</span>
        </v-btn>
      </div>
    </v-overlay>

    <v-form
      ref="form"
      class="pa-6"
      v-model="valid"
    >
      <Loading :value="loading" />
      <div class="pa-0 pb-6">
        <span>{{$t('FORM.PASSWORD_RECOVERY_TEXT')}}</span>
      </div>

      <div class="pa-0 pb-4">
        <v-text-field
          ref="email"
          v-model="email"
          :label="$t('INPUT.EMAIL')"
          :rules="[$rules.email]"
          placeholder=""
          outlined
          required
        />
      </div>

      <div class="text-center">
        <v-btn
          color="accent"
          :disabled="!valid && !loading"
          x-large
          @click="validate"
        >
          <span>{{$t('FORM.PASSWORD_RECOVERY_BTN')}}</span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'FormPasswordRecovery',
  components: {
    Loading
  },
  data: () => ({
    loading: false,
    email: '',
    valid: false,
    sent: false,
  }),

  watch: {
    loading (n) {
      this.$emit('loading', n)
    }
  },
  methods: {

    validate () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$services.api_user.user.recovery({ email: this.email })
          .then(response => {
            if (response.data) {
              this.sent = true
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    close () {
      this.sent = false
      this.$emit('close')
    },
  }
}
</script>
